
import { defineComponent, ref, onMounted, Ref } from 'vue'
import { DigInputController } from '@digithia/input/common'
import { DigInputText } from '@digithia/input/text'
import { Toaster } from '@/common/Toaster'
import router from '@/router'
import { storeAuth, StoreGettersAuth, StoreActionsAuth } from '@/store/auth'
import i18n, { __ } from '@/locales'

export default defineComponent({
  name: `ViewActivateAccount`,
  components: {},
  props: {},
  emits: [],
  setup() {
    const password = ref('')
    const passwordHackedIn = ref('')
    const loading = ref(false)
    const passwordEl: Ref<DigInputText | null> = ref(null)

    onMounted(() => {
      if (!passwordEl.value) return
      passwordEl.value.validator = (value: string) => value.length >= 8
      passwordEl.value.errors.validator = __('invalidPassword').toString()
    })

    const update = (value: string) => {
      password.value = value
      let alphabet = 0
      if (password.value.match(/[a-z]/)) {
        passwordHackedIn.value = ``
        alphabet += 26
      }
      if (password.value.match(/[A-Z]/)) {
        passwordHackedIn.value = ``
        alphabet += 26
      }
      if (password.value.match(/[0-9]/)) {
        passwordHackedIn.value = ``
        alphabet += 10
      }
      if (password.value.match(/\W|_/)) {
        passwordHackedIn.value = ``
        alphabet += 20
      }
      const totalSeconds = Math.pow(alphabet, password.value.length) / 100000000

      passwordHackedIn.value = `Mot de passe déchiffré en ${toHumanTime(
        totalSeconds,
      )} au maximum`
    }

    const toHumanTime = (time: number): string => {
      const formatter = new Intl.NumberFormat(i18n.global.locale, {
        maximumSignificantDigits: 3,
        maximumFractionDigits: 0,
      })
      if (time < 1000) {
        return `moins de 1 seconde`
      }
      time = time / 1000
      if (time < 60) {
        return `${formatter.format(time)} secondes`
      }
      time = time / 60
      if (time < 60) {
        return `${formatter.format(time)} minutes`
      }
      time = time / 60
      if (time < 24) {
        return `${formatter.format(time)} heures`
      }
      time = time / 24
      if (time < 30) {
        return `${formatter.format(time)} jours`
      }
      time = time / 30
      if (time < 12) {
        return `${formatter.format(time)} mois`
      }
      time = time / 12
      if (time < 1000) {
        return `${formatter.format(time)} années`
      }
      time = time / 1000
      if (time < 1000) {
        return `${formatter.format(time)} millénaires`
      }
      time = time / 1000
      if (time < 1000) {
        return `${formatter.format(time)} millions d'années`
      }
      time = time / 1000
      if (time < 13) {
        return `${formatter.format(time)} milliard d'années`
      }
      return `l'âge de l'univers`
    }

    const activateAccount = async (password: string) => {
      loading.value = true
      if (DigInputController.checkInputsValidity('.activate-account-form')) {
        const res = await storeAuth.dispatch(
          StoreActionsAuth.ACTIVATE_ACCOUNT,
          {
            userId: storeAuth.getters[StoreGettersAuth.USER].id,
            password,
          },
        )
        loading.value = false
        Toaster.toast(res)

        if (res.success) {
          // storeAuth.dispatch(StoreActionsAuth.LOGOUT)
          router.push({ name: 'Projects' })
        }
      } else {
        loading.value = false
        Toaster.toast({
          type: 'warning',
          message: __('invalidPassword').toString(),
        })
      }
    }

    return {
      password,
      loading,
      activateAccount,
      passwordEl,
      passwordHackedIn,
      update,
    }
  },
})
